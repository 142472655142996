/* about us */
.about-us{
  font-family: Spectral;
  /* margin-top: 150px; */
}
.work-item{
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.our-work{
  background-color: rgb(227, 227, 227);
  padding:30px 0px 100px 0px;
  margin-top: 50px;
}
.work-img{
  width: 450px;
  height: 300px;
  border-radius: 10px;
}
.containers {
  position: relative;
  width: 100%;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7); 
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: height 0.5s ease;
}
.containers:hover .overlay {
  height: 100%; 
}
.text {
  color: white;
  font-size: 20px;
  position: absolute;
  bottom:40%; 
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.5s ease;
}
.containers:hover .text {
  opacity: 1; 
}
.hr{
  width: 12%;
}
.hr1{
  width: 15%;
}
.about-us h5{
  line-height: 35px;
}
.our-work button{
  border: none;

}
.our-work button img{
  width: 40px;
  height: 40px;
}

/* end of about us */

/* contact us */
.contact-us{
  margin: 100px 0px 100px 0px;
  font-family: Spectral;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* padding: 100px 0px 50px 0px; */
}
.item1 , .item2 ,.item3{
  background-color: rgb(239, 239, 239);
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  height: 200px;
  position: relative;
}
.item1__image ,.item2__image , .item3__image{
  position: absolute;
  content: "";
  top: -33px;
  left: 42%;
}
.item1__image img{
  width: 60px;
}
.item2__image img{
  width: 60px;
}
.item3__image img{
  width: 60px;
}
a{
  color: #000;
}
/* end of contact-us */
